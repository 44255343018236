import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import Image from 'gatsby-image'
import { graphql } from 'gatsby'
import Alert from '@material-ui/lab/Alert'
import { Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import parseGeoLocation, { getHistory, setHistory } from '../helpers/navigator'
import Wrapper from '../components/Wrapper'
import Input from '../components/Input'
import Button from '../components/Button'
import SEO from '../components/SEO'
import vars from '../lib/styled'

const useStyles = makeStyles(() => ({
  alert: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    backgroundColor: '#efeeee',
    color: vars.accent,
    '& svg': {
      fill: vars.accent,
    },
  },
}))

export default ({ data }) => {
  const { home } = data
  const [defaults, setDefaults] = useState({})
  const [timer, setTimer] = useState(null)
  const cls = useStyles()

  const formikConfig = {
    initialValues: defaults,
    enableReinitialize: true,
    onSubmit: setHistory,
  }

  const runTimer = (path) => setTimer(setTimeout(() => navigate(path), 10000))

  useEffect(() => {
    const history = getHistory()
    if (history) {
      const [language, country] = history.split('-')
      setDefaults({ language, country })
      runTimer(`/${history}`)
    } else {
      parseGeoLocation((result) => {
        if (result.country && result.language) {
          result.language = result.language.split('-')[0].toLowerCase()
          setDefaults(result)
          runTimer(`/${result.language}-${result.country}`)
        }
      })
    }
  }, [])

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [timer])

  return (
    <>
      <Fade in={Boolean(timer)}>
        <Alert
          severity="error"
          className={cls.alert}
          action={
            <Button
              aria-label="close"
              color="primary"
              variant="contained"
              onClick={() => (clearTimeout(timer), setTimer(false))}
            >
              Cancel
            </Button>
          }
        >
          A language preference has been detected and you’ll be redirected in 10
          seconds.
        </Alert>
      </Fade>
      <Wrapper
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <SEO title="Welcome | Select a Language" />
        <div aria-hidden>
          <Image
            fluid={home.image.fluid}
            alt={home.image.title}
            style={{
              height: '100%',
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          />
        </div>
        <main
          style={{
            display: 'inline-block',
            position: 'relative',
            maxWidth: '95%',
            width: 500,
          }}
        >
          <Formik {...formikConfig}>
            {({ values }) => (
              <Form
                style={{
                  backgroundColor: '#FFF',
                  padding: '2rem',
                }}
              >
                <h1
                  style={{
                    fontSize: '1.877em',
                    textAlign: 'center',
                  }}
                >
                  From Where Are You Visiting?
                </h1>
                <Input
                  full
                  title="Your Country"
                  name="country"
                  component="select"
                  required
                >
                  <option value="">Choose Country</option>
                  <option value="ca">Canada</option>
                  <option value="us">United States</option>
                </Input>
                <Input
                  full
                  title="Preferred Language"
                  name="language"
                  component="select"
                  required
                >
                  <option value="">Choose Language</option>
                  <option value="en">English</option>
                  {values.country !== 'us' && (
                    <option value="fr"> French </option>
                  )}
                </Input>
                <Button
                  type="submit"
                  primary
                  style={{
                    margin: '1rem auto 0',
                    display: 'block',
                  }}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </main>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query {
    home: contentfulHomepage {
      image {
        title
        fluid(maxWidth: 2200) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }

  ## Repeatable cards
  fragment CardDetail on ContentfulPage {
    cards {
      contentful_id
      title
      subtitle
      button
      url
      body {
        childContentfulRichText {
          html
        }
      }
      image {
        title
        fluid(maxWidth: 2200) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }

  ## Featured image
  fragment PagePhoto on ContentfulPage {
    image {
      title
      fluid(maxWidth: 2200, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
  }

  ## Site meta information
  fragment PageSEO on ContentfulPage {
    title
  }

  ## Call to action blocks
  fragment CallToActionDetail on ContentfulCallToAction {
    title
    subtitle
    body {
      childContentfulRichText {
        html
      }
    }
    button: buttonText
    url
  }

  ## Used on all category queries
  fragment CategoryDetail on ContentfulCategory {
    title
    contentful_id
    image {
      title
      fluid(maxWidth: 600) {
        ...GatsbyContentfulFluid
      }
    }
  }
`
