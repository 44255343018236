import { navigate } from 'gatsby'

const localStorageName = 'lang_prefs'
const knownLanguages = ['en-us', 'en-ca', 'fr-ca']

export const getHistory = () => localStorage.getItem(localStorageName)

export const setHistory = ({ country, language }) => {
  const path = `${language}-${country}`
  if (knownLanguages.includes(path)) {
    localStorage.setItem(localStorageName, path)
    navigate(`/${path}`)
  }
}

// an approximation, really
export const isCanadianPosition = (long, lat) =>
  long >= -141 && long <= -53 && lat <= 83 && lat >= 42

export default (cb) => {
  try {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) =>
        cb({
          country: isCanadianPosition(coords.longitude, coords.latitude)
            ? 'ca'
            : 'us',
          language: window.navigator.userLanguage || window.navigator.language,
        }),
      cb,
    )
  } catch (err) {
    console.log(err)
    cb({})
  }
}
